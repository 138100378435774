var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',[_c('validation-observer',{ref:"personalDataForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-card',{staticClass:"p-2"},[_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"5","label":"Apellido paterno","label-for":"plastname"}},[_c('validation-provider',{attrs:{"name":"Apellido Paterno","rules":"max:20","vid":"plastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"plastname","state":errors.length > 0 ? false:null,"name":"plastname"},model:{value:(_vm.plastname),callback:function ($$v) {_vm.plastname=$$v},expression:"plastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"5","label":"Apellido materno","label-for":"mlastname"}},[_c('validation-provider',{attrs:{"name":"Apellido Materno","rules":"max:20","vid":"mlastname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mlastname","state":errors.length > 0 ? false:null,"name":"mlastname"},model:{value:(_vm.mlastname),callback:function ($$v) {_vm.mlastname=$$v},expression:"mlastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"5","label":"Nombres","label-for":"names"}},[_c('validation-provider',{attrs:{"name":"Nombres","rules":"max:100","vid":"names"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"names","state":errors.length > 0 ? false:null,"name":"names"},model:{value:(_vm.names),callback:function ($$v) {_vm.names=$$v},expression:"names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"Tipo de documento","label-for":"typeDocument"}},[_c('validation-provider',{attrs:{"name":"Tipo de documento","vid":"typeDocument"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"typeDocument","options":_vm.optionsTypeDocument,"state":errors.length > 0 ? false:null,"name":"typeDocument"},model:{value:(_vm.typeDocument),callback:function ($$v) {_vm.typeDocument=$$v},expression:"typeDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"Número de documento","label-for":"numberDocument"}},[_c('validation-provider',{attrs:{"name":"Número de Documento","rules":("document:" + _vm.typeDocument),"vid":"numberDocument"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"numberDocument","options":_vm.numberDocument,"state":errors.length > 0 ? false:null,"name":"numberDocument"},model:{value:(_vm.numberDocument),callback:function ($$v) {_vm.numberDocument=$$v},expression:"numberDocument"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"Fecha de nacimiento","label-for":"borndate"}},[_c('validation-provider',{attrs:{"name":"Fecha de Nacimiento","vid":"borndate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{attrs:{"id":"borndate","placeholder":"dd/mm/aaaa","name":"borndate","state":errors.length > 0 ? false:null,"config":Object.assign({}, _vm.configDatePickr, {maxDate: _vm.maxDate, minDate: _vm.minDate})},model:{value:(_vm.borndate),callback:function ($$v) {_vm.borndate=$$v},expression:"borndate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"Lugar de nacimiento","label-for":"bornplace"}},[_c('validation-provider',{attrs:{"name":"Lugar de Nacimiento","rules":"max:20","vid":"bornplace"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bornplace","state":errors.length > 0 ? false:null,"name":"bornplace"},model:{value:(_vm.bornplace),callback:function ($$v) {_vm.bornplace=$$v},expression:"bornplace"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"Nacionalidad","label-for":"citizenship"}},[_c('validation-provider',{attrs:{"name":"Nacionalidad","rules":"max:20","vid":"citizenship"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"citizenship","options":_vm.optionsCountry,"text-field":"varDescripcion","value-field":"varCodigo","state":errors.length > 0 ? false:null,"name":"citizenship"},model:{value:(_vm.citizenship),callback:function ($$v) {_vm.citizenship=$$v},expression:"citizenship"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"Residencia","label-for":"resident"}},[_c('validation-provider',{attrs:{"name":"Residencia","vid":"resident"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"resident","options":_vm.optionsResident,"value-field":"value","text-field":"text","state":errors.length > 0 ? false:null,"name":"resident"},model:{value:(_vm.resident),callback:function ($$v) {_vm.resident=$$v},expression:"resident"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"Sexo","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"Sexo","vid":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"gender","options":_vm.optionsGender,"state":errors.length > 0 ? false:null,"name":"gender"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"Estado civil","label-for":"civilstatus"}},[_c('validation-provider',{attrs:{"name":"Estado Civil","vid":"civilstatus"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"civilstatus","options":_vm.optionsCivilStatus,"state":errors.length > 0 ? false:null,"name":"civilstatus"},model:{value:(_vm.civilstatus),callback:function ($$v) {_vm.civilstatus=$$v},expression:"civilstatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"id":"email","type":"hidden","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})],1),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"sm":"12","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" Guardar y avanzar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }