exports.relationship = [
  { value: '', text: 'Selecciona una opción' },
  { value: '1', text: 'Padre' },
  { value: '2', text: 'Hijo (a)' },
  { value: '3', text: 'Hermano (a)' },
  { value: '4', text: 'Nieto (a)' },
  { value: '5', text: 'Abuelo (a)' },
  { value: '6', text: 'Tío (a)' },
  { value: '7', text: 'Sobrino (a)' },
  { value: '8', text: 'Primo (a)' },
  { value: '9', text: 'Padrastro (a)' },
  { value: '10', text: 'HIjastro (a)' },
  { value: '11', text: 'Suegro (a)' },
  { value: '12', text: 'Yerno (a)' },
  { value: '13', text: 'Hermanastro (a)' },
  { value: '14', text: 'Cuñado (a)' },
  { value: '15', text: 'Esposa (o)' },
  { value: '16', text: 'Madre' },
]

exports.civilStatus = [
  { value: '', text: 'Selecciona una opción' },
  { value: '1', text: 'Casado (a)' },
  { value: '2', text: 'Soltero (a)' },
  { value: '3', text: 'Viudo (a)' },
  { value: '4', text: 'Divorciado (a)' },
  { value: '5', text: 'Conviviente' },
]
