<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="personalDataForm">
      <b-form
        @submit.prevent="validationForm"
      >
        <b-card
          class="p-2"
        >
          <!-- paternal lastname -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="Apellido paterno"
            label-for="plastname"
          >
            <validation-provider
              #default="{ errors }"
              name="Apellido Paterno"
              rules="max:20"
              vid="plastname"
            >
              <b-form-input
                id="plastname"
                v-model="plastname"
                :state="errors.length > 0 ? false:null"
                name="plastname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- maternal lastname -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="Apellido materno"
            label-for="mlastname"
          >
            <validation-provider
              #default="{ errors }"
              name="Apellido Materno"
              rules="max:20"
              vid="mlastname"
            >
              <b-form-input
                id="mlastname"
                v-model="mlastname"
                :state="errors.length > 0 ? false:null"
                name="mlastname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- names -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="5"
            label="Nombres"
            label-for="names"
          >
            <validation-provider
              #default="{ errors }"
              name="Nombres"
              rules="max:100"
              vid="names"
            >
              <b-form-input
                id="names"
                v-model="names"
                :state="errors.length > 0 ? false:null"
                name="names"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- document type -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="2"
            label="Tipo de documento"
            label-for="typeDocument"
          >
            <validation-provider
              #default="{ errors }"
              name="Tipo de documento"
              vid="typeDocument"
            >
              <b-form-select
                id="typeDocument"
                v-model="typeDocument"
                :options="optionsTypeDocument"
                :state="errors.length > 0 ? false:null"
                name="typeDocument"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- document number -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="2"
            label="Número de documento"
            label-for="numberDocument"
          >
            <validation-provider
              #default="{ errors }"
              name="Número de Documento"
              :rules="`document:${typeDocument}`"
              vid="numberDocument"
            >
              <b-form-input
                id="numberDocument"
                v-model="numberDocument"
                :options="numberDocument"
                :state="errors.length > 0 ? false:null"
                name="numberDocument"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- born date -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="2"
            label="Fecha de nacimiento"
            label-for="borndate"
          >
            <validation-provider
              #default="{ errors }"
              name="Fecha de Nacimiento"
              vid="borndate"
            >
              <!-- <b-form-datepicker
                id="borndate"
                v-model="borndate"
                placeholder=""
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="es"
                :max="maxDate"
                name="borndate"
                :state="errors.length > 0 ? false:null"
                required
              />
              <b-form-input
                v-show="false"
                v-model="borndate"
                :state="errors.length > 0 ? false:null"
              /> -->
              <flat-pickr
                id="borndate"
                v-model="borndate"
                placeholder="dd/mm/aaaa"
                name="borndate"
                :state="errors.length > 0 ? false:null"
                :config="{ ...configDatePickr, maxDate, minDate }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- born place -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="2"
            label="Lugar de nacimiento"
            label-for="bornplace"
          >
            <validation-provider
              #default="{ errors }"
              name="Lugar de Nacimiento"
              rules="max:20"
              vid="bornplace"
            >
              <b-form-input
                id="bornplace"
                v-model="bornplace"
                :state="errors.length > 0 ? false:null"
                name="bornplace"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- citizenship -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="2"
            label="Nacionalidad"
            label-for="citizenship"
          >
            <validation-provider
              #default="{ errors }"
              name="Nacionalidad"
              rules="max:20"
              vid="citizenship"
            >
              <b-form-select
                id="citizenship"
                v-model="citizenship"
                :options="optionsCountry"
                text-field="varDescripcion"
                value-field="varCodigo"
                :state="errors.length > 0 ? false:null"
                name="citizenship"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- resident -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="2"
            label="Residencia"
            label-for="resident"
          >
            <validation-provider
              #default="{ errors }"
              name="Residencia"
              vid="resident"
            >
              <b-form-radio-group
                id="resident"
                v-model="resident"
                :options="optionsResident"
                value-field="value"
                text-field="text"
                :state="errors.length > 0 ? false:null"
                name="resident"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- gender -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="2"
            label="Sexo"
            label-for="gender"
          >
            <validation-provider
              #default="{ errors }"
              name="Sexo"
              vid="gender"
            >
              <b-form-select
                id="gender"
                v-model="gender"
                :options="optionsGender"
                :state="errors.length > 0 ? false:null"
                name="gender"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- civil status -->
          <b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="2"
            label="Estado civil"
            label-for="civilstatus"
          >
            <validation-provider
              #default="{ errors }"
              name="Estado Civil"
              vid="civilstatus"
            >
              <b-form-select
                id="civilstatus"
                v-model="civilstatus"
                :options="optionsCivilStatus"
                :state="errors.length > 0 ? false:null"
                name="civilstatus"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- dependents -->
          <!--<b-form-group
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="1"
            label="Número de dependientes"
            label-for="dependents"
          >
            <validation-provider
              #default="{ errors }"
              name="Número de Dependientes"
              rules="positive"
              vid="dependents"
            >
              <b-form-input
                id="dependents"
                v-model="dependents"
                :state="errors.length > 0 ? false:null"
                :formatter="formatterNumber"
                name="dependents"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>-->
          <!-- email -->
          <input
            id="email"
            v-model="email"
            type="hidden"
            name="email"
          >
        </b-card>
        <b-row
          class="justify-content-center"
        >
          <b-col
            sm="12"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar y avanzar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BCard, BFormGroup, BFormInput, BFormSelect, BFormRadioGroup, BButton, BRow, BCol,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import countriesOptions from '@utils/countriesOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

import { civilStatus } from '@/helpers/data'

/* eslint-disable */
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
/* eslint-enable */

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormRadioGroup,
    BButton,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
  },
  data() {
    // Max date for year validation
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxDate = new Date(today)
    maxDate.setFullYear(maxDate.getFullYear() - 18)
    const minDate = new Date(today)
    minDate.setFullYear(minDate.getFullYear() - 100)
    return {
      email: JSON.parse(localStorage.getItem('userData')).email,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      plastname: '',
      mlastname: '',
      names: '',
      typeDocument: '',
      numberDocument: '',
      borndate: '',
      bornplace: '',
      citizenship: '',
      resident: null,
      civilstatus: '',
      gender: '',
      optionsCountry: countriesOptions,
      // Options values
      optionsTypeDocument: [
        { value: '', text: 'Seleccione una opción', disabled: true },
        { value: '1', text: 'DNI' },
        { value: '2', text: 'Carnét PNP' },
        { value: '3', text: 'Carnét FFAA' },
        { value: '4', text: 'Carnet Extranjeria' },
        { value: '6', text: 'RUC' },
        { value: '7', text: 'Pasaporte' },
      ],
      optionsGender: [
        { value: '', text: 'Selecciona una opción' },
        { value: '0', text: 'Femenino' },
        { value: '1', text: 'Masculino' },
      ],
      optionsResident: [
        { value: '1', text: 'Sí' },
        { value: '0', text: 'No' },
      ],
      optionsCivilStatus: civilStatus,
      // Date max & min date
      maxDate,
      minDate,
      // Validation rules
      required,

      // Configs DatePickr
      configDatePickr: {
        allowInput: true,
        altInput: true,
        locale: Spanish,
        altFormat: 'd/m/Y',
      },
    }
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.plastname = dataU.personalData && dataU.personalData.plastname ? dataU.personalData.plastname : ''
    this.mlastname = dataU.personalData && dataU.personalData.mlastname ? dataU.personalData.mlastname : ''
    this.names = dataU.personalData && dataU.personalData.names ? dataU.personalData.names : ''
    this.typeDocument = dataU.personalData && dataU.personalData.typeDocument ? dataU.personalData.typeDocument : ''
    this.numberDocument = dataU.personalData && dataU.personalData.numberDocument ? dataU.personalData.numberDocument : ''
    this.borndate = dataU.personalData && dataU.personalData.borndate ? dataU.personalData.borndate : ''
    this.bornplace = dataU.personalData && dataU.personalData.bornplace ? dataU.personalData.bornplace : ''
    this.citizenship = dataU.personalData && dataU.personalData.citizenship ? dataU.personalData.citizenship : ''
    this.resident = dataU.personalData && dataU.personalData.resident ? dataU.personalData.resident : '1'
    this.gender = dataU.personalData && dataU.personalData.gender ? dataU.personalData.gender : ''
    this.civilstatus = dataU.personalData && dataU.personalData.civilstatus ? dataU.personalData.civilstatus : ''
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    validationForm() {
      this.$refs.personalDataForm.validate().then(success => {
        if (success) {
          useJwt.personalData({
            plastname: this.plastname,
            mlastname: this.mlastname,
            names: this.names,
            typeDocument: this.typeDocument,
            numberDocument: this.numberDocument,
            borndate: this.borndate,
            bornplace: this.bornplace,
            citizenship: this.citizenship,
            resident: this.resident,
            gender: this.gender,
            civilstatus: this.civilstatus,
            email: this.email,
            ruc: this.ruc,
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              this.$router.replace('/registro/datos-contacto-ubicacion')
              store.dispatch(
                'elementVerticalMenu/setStatusStep',
                { step: 'Personal-data', status: user.personalData.completed },
                { root: true },
              )
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Éxito',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Se ha guardado tu información con éxito.',
                  autoHideDelay: 5000,
                },
              })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.personalDataForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
